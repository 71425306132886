* {
    box-sizing: border-box;

    font-family: "Syncopate", sans-serif;
    font-weight: 700;
    font-style: normal;
}

html,
body,
#root,
.page_box {
    width: 100%;
    height: 100%;
}

.regular {
    font-family: "Vast Shadow", serif;
    font-weight: 400;
    font-style: normal;
}

.bold {
    font-family: "Vast Shadow", serif;
    font-weight: 400;
    font-style: normal;
}

::-webkit-scrollbar {
    /* 滚动条整体部分 */
    width: 6px;
    margin-right: 0px;
}

::-webkit-scrollbar:horizontal {
    height: 4px;
    margin-bottom: 4px;
}

::-webkit-scrollbar-thumb {
    /* 滑块 */
    width: 2px;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:hover {
    /* 鼠标移入滑块 */
    background: #909090;
}

@keyframes scaleUpDown {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    to {
        opacity: 1;
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

.header-box {
}

.page {
    width: 100%;
    height: 100%;
    background: url("../images/bg.png") no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @media (max-width: 900px) {
        background: url("../images/bg1.png") no-repeat;
        background-size: 100% 100%;
        background-position: center center;
        align-items: start;
    }
    .box {
        width: 8rem;
        padding: 0.68rem 0.44rem 0.24rem 0.44rem;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 0.4rem;
        border: 0.02rem solid #fd7588;
        backdrop-filter: blur(0.32rem);
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width: 900px) {
            width: 6rem;
            margin-top: 22.9%;
            padding-bottom: 0.55rem;
        }
        .logo {
            width: 2rem;
            height: 2rem;
            img {
                width: 100%;
            }
        }
        .title {
            font-family: "Lato", sans-serif;
            font-weight: bold;
            font-size: 0.36rem;
            color: #fd7588;
            line-height: 0.44rem;
            margin-top: 0.2rem;
            @media (max-width: 900px) {
                font-size: 0.48rem;
                line-height: 0.57rem;
                margin-top: 0.16rem;
            }
        }
        .tip_text {
            width: 5.2rem;
            font-family: "Lato", sans-serif;
            font-weight: normal;
            font-size: 0.22rem;
            color: #fd7588;
            line-height: 0.27rem;
            text-align: center;
            font-style: italic;
            margin-top: 0.16rem;
            @media (max-width: 900px) {
                font-size: 0.26rem;
                line-height: 0.32rem;
                margin-top: 0.12rem;
            }
        }
        .app_store {
            width: 3.32rem;
            margin-top: 0.48rem;
            img {
                width: 100%;
            }
        }
    }
    .link_box,
    .link_box1 {
        margin-top: 0.49rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        @media (max-width: 900px) {
            display: none;
        }
        .left {
        }
        .text {
            font-family: "Lato", sans-serif;
            font-weight: 400;
            font-size: 0.14rem;
            color: #ffffff;
            line-height: 0.17rem;
            opacity: 0.4;
            @media (max-width: 900px) {
                font-size: 0.22rem;
                line-height: 0.27rem;
            }
        }
        .ml {
            margin-left: 0.24rem;
        }
    }
    .link_box1 {
        width: 100%;
        position: fixed;
        bottom: 0.3rem;
        left: 0;
        display: none;
        padding: 0 0.4rem;
        @media (max-width: 900px) {
            display: flex;
        }
    }
}

.pact-page {
    width: 100%;
    height: 100%;
    background: url("../images/bg.png") no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (max-width: 900px) {
        justify-content: start;
        background: url("../images/bg1.png") no-repeat;
        background-size: cover;
        background-position: center center;
        padding: 0 0.4rem;
    }
    .title {
        font-family: "Lato", sans-serif;
        font-weight: bold;
        font-size: 0.4rem;
        color: #ffe8dc;
        line-height: 0.48rem;
        text-align: center;
        margin-bottom: 0.2rem;
        display: none;
        @media (max-width: 900px) {
            display: block;
            margin-top: 1.1rem;
            font-size: 0.48rem;
            line-height: 0.57rem;
        }
    }
    .box {
        width: 64.58%;
        height: 83.33%;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 0.4rem;
        border: 0.02rem solid #fd7588;
        padding: 0.52rem 10.2%;
        position: relative;
        z-index: 2;

        &::after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            backdrop-filter: blur(0.32rem);
            border-radius: 0.4rem;
        }
        @media (max-width: 900px) {
            width: 100%;
            padding: 0.48rem 0.4rem;
        }
        .title {
            color: #fd7588;
            display: block;
            @media (max-width: 900px) {
                display: none;
            }
        }
        .pact-cont {
            width: 100%;
            height: 95%;
            overflow: auto;
            scrollbar-width: none; /* Firefox */
            &::-webkit-scrollbar {
                display: none;
            }
            @media (max-width: 900px) {
                height: 100%;
            }
            p,
            li {
                font-family: "Lato", sans-serif;
                padding-bottom: 0.3rem;
                font-weight: 500;
                font-size: 0.16rem;
                line-height: 0.26rem;
                color: #ffe8dc;
                @media (max-width: 900px) {
                    font-size: 0.26rem;
                    line-height: 0.39rem;
                }
            }

            h2 {
                font-family: "Lato", sans-serif;
                font-size: 0.22rem;
                line-height: 0.28rem;
                font-weight: 700;
                color: #fd7588;
                @extend .bold;
                margin-bottom: 0.14rem;
                margin-top: 0.26rem;
                border-radius: 0.12rem;
                @media (max-width: 900px) {
                    font-size: 0.36rem;
                    line-height: 0.43rem;
                }
            }

            strong {
                font-weight: bold;
            }
        }
    }
}
